
import HeaderTit from '@/components/HeaderTit.vue'
import AxiosAdapter from '../request/AxiosAdapter'
import { defineComponent, reactive, onMounted, ref } from 'vue'
import { mobileReg } from '../commons/unit'
import { ElMessage } from 'element-plus'
import { useRouter } from 'vue-router'
import { getRecord } from '../commons/Utils'
import LinkAge from '@/components/Selection.vue'
import { StringObjectType } from '../commons/Types'
import { requestFunc, getRefFunc } from '../service/Linkage'
import { throwCustomFunction } from '../commons/ErrorHandle'

export default defineComponent({
  name: 'PersonalConfirmAddress',
  components: {
    HeaderTit,
    LinkAge
  },
  setup() {
    const identityTypeList = [
      {
        code: 1,
        name: '居民身份证'
      },
      {
        code: 2,
        name: '中国公民护照'
      },
      {
        code: 7,
        name: '统一社会信用代码'
      },
      {
        code: 9,
        name: '律师证'
      }
    ]
    // const areaForm: GetAreaData = {
    //   parentId: ''
    // }
    const areaOptions: StringObjectType<any> = reactive({
      shengList: [],
      shiList: [],
      quList: []
    })
    const province = ref(0)
    // 初始化省
    requestFunc().then((value) => (areaOptions.shengList = value))
    let cityResolve
    let districtResolve
    const cityPromise = new Promise<void>((resolve) => (cityResolve = resolve))
    const districtPromise = new Promise<void>(
      (resolve) => (districtResolve = resolve)
    )

    const city = getRefFunc(areaOptions, 'shiList', province, true, cityResolve)
    const district = getRefFunc(
      areaOptions,
      'quList',
      city,
      false,
      districtResolve
    )

    const ruleForm = reactive({
      caseCause: '',
      caseNumberWord: '',
      caseUserName: '',
      identityType: '',
      identityNumber: '',
      judgeName: '',
      courtName: '',
      getDate: '',
      electronicId: '11',
      mobilephone: '',
      mobilephone2: '',
      postalCode: '',
      detailAddress: '',
      province,
      city,
      district,
      email: '',
      email2: '',
      relationType: '1',
      provinceCode: '' || 0,
      provinceName: '',
      cityCode: '' || 0,
      cityName: '',
      districtCode: '' || 0,
      districtName: '',
      name: '',
      confirm: true,
      isElectronic: false
    })

    // const provinceOptions: any = reactive([])
    // const cityOptions: any = reactive([])
    // const districtOptions: any = reactive([])
    const router = useRouter()
    const submit = async () => {
      if (!mobileReg.test(ruleForm.mobilephone)) {
        throwCustomFunction('请输入正确的手机号')
      }
      if (ruleForm.mobilephone2 && !mobileReg.test(ruleForm.mobilephone2)) {
        throwCustomFunction('请输入正确的手机号')
      }
      if (!ruleForm.cityCode) {
        throwCustomFunction('请选择所在地区')
      }
      if (!ruleForm.detailAddress) {
        throwCustomFunction('请输入详细地址')
      }

      ruleForm.provinceCode = ruleForm.province
      ruleForm.cityCode = ruleForm.city
      ruleForm.districtCode = ruleForm.district

      await AxiosAdapter.post({
        url: '/webElectronic/userInfoSaveAddress',
        data: ruleForm
      })
      // AxiosAdapter.post({url: '/webElectronic/userInfoSaveAddress', data: ruleForm})
      ElMessage.success({
        type: 'success',
        message: '保存成功',
        duration: 3000
      })
      router.push({
        path: '/personal-center'
      })
    }
    // const getProvinceList = async () => {
    //   const data = await AxiosAdapter.get({url: '/city/list'})
    //   provinceOptions.values = data
    // }
    // const getCityList = async (code: any) => {
    //   if (!code) {
    //     cityOptions.values = []
    //     districtOptions.values = []
    //     ruleForm.cityCode = ''
    //     ruleForm.districtCode = ''
    //   } else {
    //     const data = await AxiosAdapter.get({url: '/baseArea/cityList', data: { parentCode: code }})
    //     cityOptions.values = data
    //   }

    // }
    // const getDistrictList = async (code: any) => {
    //   if (!code) {
    //     districtOptions.values = []
    //     ruleForm.districtCode = ''
    //   } else {
    //     const data = await AxiosAdapter.get({url: '/city/list', data: { parentCode: code }})
    //     districtOptions.values = data
    //   }

    // }

    const getConfirmAddress = async () => {
      const data = await AxiosAdapter.get<any, any>({
        url: '/webElectronic/getConfirmAddress'
      })
      ruleForm.name = data.name
      ruleForm.caseCause = data.caseCause
      ruleForm.caseNumberWord = data.caseNumberWord
      ruleForm.caseUserName = data.caseUserName
      ruleForm.identityType = data.identityType
      ruleForm.identityNumber = data.identityNumber
      ruleForm.judgeName = data.judgeName
      ruleForm.courtName = data.courtName
      ruleForm.getDate = data.getDate
      ruleForm.electronicId = data.electronicId
      ruleForm.mobilephone = data.mobilephone
      ruleForm.mobilephone2 = data.mobilephone2
      ruleForm.postalCode = data.postalCode
      ruleForm.detailAddress = data.detailAddress
      ruleForm.email = data.email
      ruleForm.email2 = data.email2
      ruleForm.relationType = data.relationType
      ruleForm.provinceCode = data.provinceCode
      ruleForm.cityCode = data.cityCode
      ruleForm.districtCode = data.districtCode
      province.value = data.provinceCode
      city.value = data.cityCode
      district.value = data.districtCode
      cityPromise.then(() => (city.value = data.cityCode))
      districtPromise.then(() => (district.value = data.districtCode))
    }

    onMounted(async () => {
      // getProvinceList()
      getRecord('进入地址确认书页面', true)
      await getConfirmAddress()
    })
    return {
      submit,
      ruleForm,
      areaOptions,
      // provinceOptions,
      // cityOptions,
      // districtOptions,
      // getProvinceList,
      // getCityList,
      // getDistrictList,
      identityTypeList
    }
  }
})
